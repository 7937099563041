import { createReducer } from '@reduxjs/toolkit'
import { PartnerT } from 'shared/types/model'
import { receivePartner, clear, logout } from 'model/actions'

const initialState = null as PartnerT | null

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(receivePartner, (state, action) => action.payload)
    .addCase(clear, () => initialState)
    .addCase(logout, () => initialState)
})

export default reducer
