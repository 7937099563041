import * as Sentry from '@sentry/react'
import { dbRegisterUser, fetchAccount, fetchNoTourData, fetchUserAccounts } from 'controllers/account'
import { auth, db } from 'controllers/db'
import { acceptInvitation, fetchInvitations } from 'controllers/invitations'
import { addListener, clearListenersExceptUser, existingUser, setExistingUser } from 'controllers/listeners'
import { dbFetchPartner } from 'controllers/partner'
import { fetchAccountProfile, fetchTeamProfiles } from 'controllers/profiles'
import { fetchRequestsDesign } from 'controllers/requestDesign'
import { fetchRoomTypes } from 'controllers/roomTypes'
import { fetchSlots } from 'controllers/slots'
// import { fetchPrices, fetchProducts, fetchSubscription, openPaymentPage } from 'controllers/settings'
import {
  fetchPrices,
  fetchProducts,
  fetchSubscription,
  openPaymentPage
} from 'controllers/stripe'
import { fetchSuppliers } from 'controllers/suppliers'
import { fetchTemplates } from 'controllers/templates'
import { fetchTourModels } from 'controllers/tourModels'
import { fetchTours } from 'controllers/tours'
import { dbGetUser } from 'controllers/user'
import { onAuthStateChanged, User } from 'firebase/auth'
import { doc, onSnapshot } from 'firebase/firestore'
import _ from 'lodash'
import { clear, receiveAuthData, receiveUser } from 'model/actions'
// import { fetchItems } from 'controllers/items'
// import { fetchUser } from 'controllers/user'
import store from 'model/store'
import { NavigateFunction } from 'react-router-dom'
import { CustomerT, InvitationT } from 'types/model'

// import { dbSignOut } from 'controllers/auth'

const initAccountData = async (
  accountId: string,
  userId: string
  // navigate: NavigateFunction
) => {
  try {
    console.log('INIT', userId)
    await Promise.all([
      fetchAccount(accountId),
      fetchAccountProfile(accountId),
      fetchTeamProfiles(accountId, userId),
      fetchTourModels(accountId),
      fetchTours(accountId),
      fetchPrices(),
      fetchProducts(),
      fetchSubscription(accountId),
      fetchRequestsDesign(accountId),
      fetchInvitations(accountId),
      fetchNoTourData(accountId)
    ])
    console.log('initialized')
  } catch (e) {
    Sentry.captureException(e)
  }
}

const fetchPublicData = async () => {
  try {
    await Promise.all([
      fetchSlots(),
      fetchRoomTypes(),
      fetchSuppliers(),
      fetchTemplates()
    ])
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const fetchPartner = async () => {
  console.log('fetchPublicData', window.location)
  const hostnameAr = _.split(window.location.hostname, '.')
  const subdomain = hostnameAr[0]
  await dbFetchPartner(subdomain)
  return null
}

const userChanged = async (
  user: CustomerT,
  isLocalChange: boolean,
  navigate: NavigateFunction
) => {
  try {
    const currentAccountNow = _.get(existingUser, 'currentAccountId')
    console.log(
      '%cuser changed, currentAccountNow',
      'color: lightgreen',
      currentAccountNow
    )
    console.log(
      '%cuser changed, newAccountId',
      'color: lightgreen',
      _.get(user, 'currentAccountId')
    )
    if (!currentAccountNow && user && user.currentAccountId && !isLocalChange) {
      console.log('userChanged 1')
      store.dispatch(receiveUser(user))
      await initAccountData(user.currentAccountId, user.id)
    } else if (
      user &&
      user.currentAccountId &&
      currentAccountNow !== user.currentAccountId &&
      !isLocalChange
    ) {
      console.log('userChanged 2')
      store.dispatch(clear())
      clearListenersExceptUser()
      store.dispatch(receiveUser(user))
      navigate('/')
      await initAccountData(user.currentAccountId, user.id)
    } else if (!isLocalChange) {
      console.log('userChanged 3')
      store.dispatch(receiveUser(user))
    } else {
      console.log('userChanged 4')
      store.dispatch(receiveUser(user))
    }
  } catch (e) {
    console.log('userChanged error', e)
    Sentry.captureException(e)
  }
}

const fetchUser = async (authData: User, navigate: NavigateFunction) => {
  const userId = authData.uid
  console.log('fetch user', userId)
  try {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    StonlyWidget('identify', userId)
    const unsubscribe = onSnapshot(
      doc(db, 'customers', userId),
      { includeMetadataChanges: true },
      userSN => {
        console.log('fetchUser, authData', authData)
        const isLocalChange = userSN.metadata.hasPendingWrites
        console.log('isLocalChange', isLocalChange)
        const user = userSN.data() as CustomerT | null
        const authUser = auth.currentUser
        if (!isLocalChange && authUser) {
          if (user) {
            console.log('user received', user)
            userChanged(user, isLocalChange, navigate)
            setExistingUser(user)
            fetchUserAccounts(user)
          } else {
            setExistingUser(null)
          }
        }
      },
      err => {
        console.log('fetch user error', err)
        Sentry.captureMessage('fetch user error')
        Sentry.captureException(err)
      }
    )
    addListener('user', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetch user error', e)
  }
}

const registerUser = async (
  authUser: User,
  invitation: InvitationT | undefined,
  priceId: string | null
) => {
  try {
    const user = await dbGetUser(authUser.uid)
    console.log('customer user', user)
    if (_.isNil(user)) {
      await dbRegisterUser()
    }
    if (invitation) {
      await acceptInvitation(invitation.id)
    }
    if (priceId) {
      openPaymentPage(priceId)
    }
  } catch (e) {
    console.error('updateCustomerProfile error', e)
    Sentry.captureException(e)
  }
}

const onAuth = async (
  authData: User | null,
  navigate: NavigateFunction,
  invitation: InvitationT | undefined,
  priceId: string | null
) => {
  // console.log('onAuthStateChanged', authData)
  const pathname = window.location.pathname
  const search = window.location.search
  if (authData) {
    console.log('authData', authData)
    await registerUser(authData, invitation, priceId)
    store.dispatch(
      receiveAuthData({ uid: authData.uid, email: authData.email })
    )
    const fromOnboarding = _.includes(search, 'onboarding')
    if (
      _.startsWith(pathname, '/auth') ||
      _.startsWith(pathname, '/onboarding')
    ) {
      console.log('navigate to homepage')
      if (fromOnboarding) {
        console.log('adding stonly flag')
        // @ts-ignore
        // eslint-disable-next-line no-undef
        StonlyWidget('identify', authData.uid, {
          'design-complete': 'true'
        })
        const params = new URLSearchParams(search)
        const tourId = params.get('onboarding')
        navigate(
          { pathname: `/tour/${tourId}`, search: '?onboarding' },
          { state: { tab: 1 } }
        )
      } else {
        navigate('/')
      }
    }
    fetchUser(authData, navigate)
  } else {
    // setExistingUser(null)
    // clearListeners()
    // store.dispatch(logout())
    let redirectToAuth = true
    if (
      _.startsWith(pathname, '/auth') ||
      _.startsWith(pathname, '/onboarding') ||
      _.startsWith(pathname, '/preview')
    ) {
      redirectToAuth = false
    }
    if (redirectToAuth) {
      navigate('/auth/signin', { state: { invitation } })
    }
  }
}

export const appInitialized = (
  navigate: NavigateFunction,
  invitation: InvitationT | undefined,
  priceId: string | null
): void => {
  console.log('%cAPP Initialized', 'color: red;', 'priceId', priceId)
  try {
    fetchPublicData()
    onAuthStateChanged(auth, authData =>
      onAuth(authData, navigate, invitation, priceId)
    )
  } catch (e) {
    Sentry.captureException(e)
    console.log('app initialization error', e)
  }
}
